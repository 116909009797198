import GitBranch from "./GitBranch";
import {Pipeline} from "./Pipeline";

interface PipelineState {
    id: string,
    orgId: string,
    projectName: string,
    branch: GitBranch,
    pipeline: Pipeline | null,
    state: State,
    startTime: string,
    logs: string[],
}

export default PipelineState

export enum State {
    INITIALISING = "INITIALISING" as any,
    FAILED_TO_INITIALISE = "FAILED_TO_INITIALISE" as any,
    RUNNING = "RUNNING" as any,
    FINISHED = "FINISHED" as any,
    FAILED_TO_RUN = "FAILED_TO_RUN" as any,
    FAILED_TO_TEAR_DOWN = "FAILED_TO_TEAR_DOWN" as any,
}