import {FormEvent, useEffect, useState} from "react";
import {AutoComplete, Input, LoadingSpinner} from "@matillion/component-library";
import Api from "../../api/Api";
import {useApi} from "@matillion/octo-react-util";

interface ActionParameterInputProps {
    parameterDocumentation: any
    selectedParameter: any | undefined
    setSelectedParameter: (parameter: any) => void
    disabled: boolean
}

const ActionParameterInput = ({parameterDocumentation, selectedParameter, setSelectedParameter, disabled}: ActionParameterInputProps) => {
    const [options, setOptions] = useState<string[]>()
    const api = useApi<Api>()

    useEffect(() => {
        if(parameterDocumentation.options?.location) api.get<string[]>(parameterDocumentation.options.location).then(setOptions)
    }, [api, parameterDocumentation.options?.location])

    if(parameterDocumentation.options?.location) {
        if(!options) return <LoadingSpinner/>
        return <AutoComplete
            allowFreetext={true}
            value={{id: selectedParameter, name: selectedParameter}}
            onChange={({target: {value}}) => {if (value) setSelectedParameter(value.name)}}
            availableItems={options.map((option) => ({id: option, name: option}))}
        />
    }
    else return <Input
        placeholder={parameterDocumentation.type}
        value={selectedParameter ? selectedParameter : ""}
        onChange={(e: FormEvent<HTMLInputElement>) => {
            setSelectedParameter(e.currentTarget.value)
        }}
        disabled={disabled}
    />
}

export default ActionParameterInput