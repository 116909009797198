import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
    DropResult
} from "react-beautiful-dnd";
import PipelineStepCard from "./PipelineStepCard";
import {PipelineStepDefinition} from "../../interfaces/PipelineDefinitionsFile";
import produce from "immer";

interface DraggableStepsProps {
    steps: PipelineStepDefinition[]
    setSteps: (steps: PipelineStepDefinition[]) => void
}

const DraggableSteps = ({steps, setSteps}: DraggableStepsProps) => {
    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }
        setSteps(
            produce(steps, draft => {
                const element = draft[result.source.index];
                draft.splice(result.source.index, 1);
                draft.splice(result.destination!.index, 0, element);
            })
        );
    }

    function setStep(stepIndex: number, step: PipelineStepDefinition | undefined) {
        setSteps(
            produce(steps, draft => {
                if(step) {
                    draft[stepIndex] = step;
                } else {
                    draft.splice(stepIndex, 1)
                }
            })
        );
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="pipeline">
                {(droppableProvided: DroppableProvided) => (
                    <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                        {steps.map((step, index) => (
                            <Draggable key={step.name} draggableId={step.name} index={index}>
                                {(draggableProvided: DraggableProvided) => (
                                    <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                                        <PipelineStepCard step={step} setStep={(newStep: PipelineStepDefinition | undefined) => setStep(index, newStep)}/>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DraggableSteps