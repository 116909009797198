import {Button, Grid, Label, LoadingSpinner, Section, Typography} from "@matillion/component-library";
import {Divider} from "@mui/material";
import PipelineState, {State} from "../interfaces/PipelineState";
import Api from "../api/Api";
import StateIcon from "./StateIcon";
import StepsList from "./StepsList";
import Logs from "./Logs";
import useUpdatingState from "../hooks/UseUpdatingState";
import {useCallback, useEffect, useState} from "react";
import {useApi} from "@matillion/octo-react-util";
import {Link, useNavigate, useParams} from "react-router-dom";

const PipelineStateView = ({baseRoute}: {baseRoute: string}) => {
    const {id} = useParams()
    const api = useApi<Api>()
    const updatePipeline = useCallback(() => api.getPipelineState(id!), [api, id])
    const {state: pipelineState, setUpdating, error} = useUpdatingState<PipelineState>(updatePipeline)
    const [deleting, setDeleting] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if(error !== undefined || (pipelineState
            && pipelineState?.state !== State.INITIALISING
            && pipelineState?.state !== State.RUNNING))
            setUpdating(false)
    })

    async function deleteState() {
        setDeleting(true)
        await api.deletePipelineState(id!)
        navigate(`/${baseRoute}`)
    }

    if(error !== undefined) return <><Typography>{error.message}</Typography><Link to={`/${baseRoute}`}>Back</Link></>
    if(!pipelineState) return <LoadingSpinner/>
    else return (
        <Grid>
            <Section>
                <div>
                    <Typography style={{color: "green"}} as="h1" format="dtm"
                                weight="bold">{id}</Typography>
                    <Divider/>
                </div>
            </Section>
            <Section size={pipelineState.pipeline ? 4 : 12}>
                <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                    <Label title="StateIcon"><StateIcon state={pipelineState.state}/></Label>
                    <Label title="Organisation ID"><Typography>{pipelineState.orgId}</Typography></Label>
                    <Label title="Project Name"><Typography>{pipelineState.projectName}</Typography></Label>
                    <Label title="Branch Name"><Typography>{pipelineState.branch.branchName}</Typography></Label>
                    <Label title="Ref ID"><Typography>{pipelineState.branch.refId}</Typography></Label>
                    <Label title="Start Time"><Typography>{pipelineState.startTime}</Typography></Label>
                    <Label title="Pipeline Logs"><Typography><Logs logs={pipelineState.logs} /></Typography></Label>
                    <Button text={"Delete State"} alt={"red"} onClick={deleteState} waiting={deleting} />
                    <Link to={`/${baseRoute}`}><Button alt={"text"}>Back to list</Button></Link>
                </div>
            </Section>
            {pipelineState.pipeline ?
                <Section size={8}>
                    <StepsList steps={pipelineState.pipeline.steps}/>
                </Section>
                : null
            }
        </Grid>
    )
}

export default PipelineStateView