import {Label, Typography} from "@matillion/component-library";
import StateIcon from "./StateIcon";
import {PipelineStep} from "../interfaces/Pipeline";
import JsonAsTable from "./JsonAsTable";
import Logs from "./Logs";
import StepsList from "./StepsList";
import Module from "../interfaces/Module";

interface StepDetailsProps {
    step: PipelineStep
}

const StepDetails = ({step}: StepDetailsProps) => {
    const module = step.service === "MODULE" ? (step as any)["module"] as Module : null;

    return (
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <Label title={"State"}><StateIcon state={step.state}/></Label>
            <Label title={"Execution Time"}><Typography>{`${step.executionTimeMillis} ms`}</Typography></Label>
            <Label title={"Step Logs"}><Logs logs={step.logs}/></Label>
            <Label title={"Service"}><Typography>{step.service}</Typography></Label>
            <Label title={"Action"}><Typography>{step.action}</Typography></Label>
            {step.actionParameters ?
                <Label title={"Action Parameters"}><JsonAsTable json={step.actionParameters} /></Label>
                : null}
            {step.actionOutputVariableMapping ?
                <Label title={"Action Output Variable Mapping"}><JsonAsTable json={step.actionOutputVariableMapping} /></Label>
                : null}
            {module ? <StepsList steps={module.steps}/> : null}
            {module?.inputVariables ?
                <Label title={"Module input variables"}><ul>{module.inputVariables.map(v => <li>{v}</li>)}</ul></Label>
                : null}
        </div>
    )
}

export default StepDetails