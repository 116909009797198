const JsonAsTable = ({json}: {json: { [name: string]: string }}) => {
    const tableRows = Object.entries(json).map((entry) => {
        return (
            <tr>
                <td>{`${entry[0]}:`}</td>
                <td>{entry[1]}</td>
            </tr>
        )
    })
    return (
        <table>
            {tableRows}
        </table>
    )
}

export default JsonAsTable