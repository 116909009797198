import PipelineState from "../interfaces/PipelineState";
import {BaseApi} from "@matillion/octo-react-util";
import yaml from "js-yaml";
import {PipelineDefinitionsFile} from "../interfaces/PipelineDefinitionsFile";
import ModuleDefinitionFile from "../interfaces/ModuleDefinitionFile";

class Api extends BaseApi {

    async getAllPipelineStates(projectName: string = ""): Promise<PipelineState[]> {
        return this.get(`/api/pipelineStates${projectName!==""?`/projectName/${projectName}`:""}`)
    }

    async getPipelineState(id: string): Promise<PipelineState> {
        return this.get(`/api/pipelineStates/id/${id}`)
    }

    async deletePipelineState(id: string) {
        await this.delete(`/api/pipelineStates/id/${id}`)
    }

    async getAllProjectNames(): Promise<string[]> {
        return this.get(`/api/pipelineStates/projectName`)
    }

    async getLatestPipelineState(projectName: string, branchName: string): Promise<PipelineState | undefined> {
        try {
            return await this.get(`/api/pipelineStates/projectName/${projectName}/branchName/${branchName}`)
        } catch (e) {
            return undefined
        }
    }

    async getPipelineDefinitionsFile(): Promise<PipelineDefinitionsFile | undefined> {
        try {
            return yaml.load(await this.getRaw(`/api/pipelineDefinitionsFile`, {"accept": "application/x-yaml"})) as PipelineDefinitionsFile
        } catch (e) {
            return undefined
        }
    }

    async savePipelineDefinitionsFile(pipelineDefinitionsFile: PipelineDefinitionsFile) {
        const rawYaml = yaml.dump(pipelineDefinitionsFile)
        return this.putRaw(`/api/pipelineDefinitionsFile`, rawYaml,{"content-type": "application/x-yaml"})
    }

    async getAllModuleDefinitionFileNames(): Promise<string[]> {
        return this.get(`/api/moduleDefinitionFile`)
    }

    async getModuleDefinitionFile(moduleName: string): Promise<ModuleDefinitionFile | undefined> {
        try {
            return yaml.load(await this.getRaw(`/api/moduleDefinitionFile/${moduleName}`, {"accept": "application/x-yaml"})) as ModuleDefinitionFile
        } catch (e) {
            return undefined
        }
    }

    async saveModuleDefinitionFile(moduleName: string, moduleDefinitionFile: ModuleDefinitionFile) {
        const rawYaml = yaml.dump(moduleDefinitionFile)
        return this.putRaw(`/api/moduleDefinitionFile/${moduleName}`, rawYaml,{"content-type": "application/x-yaml"})
    }

}

export default Api