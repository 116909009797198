import {AutoComplete, Button, DataGrid, Label, SortState} from "@matillion/component-library";
import Api from "../api/Api";
import {useEffect, useState} from "react";
import PipelineState from "../interfaces/PipelineState";
import StateIcon from "./StateIcon";
import {useApi} from "@matillion/octo-react-util";
import {Link} from "react-router-dom";

const PipelineStateList = () => {
    const api = useApi<Api>()
    const [pipelineStates, setPipelineStates] = useState<PipelineState[]>([])
    const [projectNames, setProjectNames] = useState<string[]>([])
    const [projectName, setProjectName] = useState<string>()

    useEffect(() => {
        (async () => setProjectNames(await api.getAllProjectNames()))();
    }, [api])

    useEffect(() => {
        (async () => setPipelineStates(await api.getAllPipelineStates(projectName)))();
    }, [api, projectName])

    function sortByTime(dir: string) {
        setPipelineStates(
            [...pipelineStates].sort((a: PipelineState, b: PipelineState) => {
                const aTime = new Date(dir === "ASC" ? a.startTime : b.startTime);
                const bTime = new Date(dir === "ASC" ? b.startTime : a.startTime);
                if(aTime > bTime) return -1
                else if(bTime > aTime) return 1
                else return 0
            })
        )
    }

    return (
        <div>
            <Link to={"edit"}><Button>Edit Pipeline</Button></Link>
            <Label title={"Select Project"}>
                <AutoComplete
                    onChange={({target: {value}}) => {
                        if (value) setProjectName(value.name)
                    }}
                    availableItems={projectNames.map((name) => ({id: name, name: name}))}
                    value={projectName ? {id:projectName, name: projectName} : null}
                    allowFreetext={false}
                />
            </Label>
            <DataGrid
                columns={[
                    {
                        key: 'id',
                        title: 'Pipeline Id'
                    },
                    {
                        key: 'projectName',
                        title: 'Project Name'
                    },
                    {
                        key: 'branchName',
                        title: 'PipelineBranch Name'
                    },
                    {
                        key: 'state',
                        title: 'State',
                        as: StateIcon
                    },
                    {
                        key: 'startTime',
                        title: 'Start Time',
                        sortable: true,
                    },
                    {
                        as: Link,
                        key: 'details',
                        title: ''
                    },
                ]}
                onSort={(arg: SortState<{ startTime: string }>) => sortByTime(arg.startTime)}
                rows={pipelineStates.map((row) => ({
                    branchName: row.branch.branchName,
                    details: {
                        children: <Button>Details</Button>,
                        to: `id/${row.id}`
                    },
                    ...row,
                    state: {
                        state: row.state
                    },
                }))}
            />
        </div>
    )
}

export default PipelineStateList