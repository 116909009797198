import {AutoComplete, Button, Field, Grid, Modal, Section} from "@matillion/component-library";
import {PipelineBranchId, PipelineDefinitionsFile} from "../../interfaces/PipelineDefinitionsFile";
import {FormEvent, useState} from "react";
import produce from "immer";

interface BranchControlsProps {
    pipelineDefinitionsFile: PipelineDefinitionsFile
    setPipelineDefinitionsFile: (pipelineDefinitionsFile: PipelineDefinitionsFile) => void
    branch: PipelineBranchId,
    setBranch: (branch: PipelineBranchId) => void
    disabled: boolean
}

const BranchControls = ({pipelineDefinitionsFile, setPipelineDefinitionsFile, branch, setBranch, disabled}: BranchControlsProps) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [newBranchName, setNewBranchName] = useState<string>()

    const branchAutoCompleteItems: { id: string | number, name: string }[] =
        Object.keys(pipelineDefinitionsFile.pipelines.branches)
            .map((branchName: string) => ({id: branchName, name: branchName}))
    branchAutoCompleteItems.push({id: 0, name: "Default"})

    const addPipelineDefinitionModal = (
        <Modal onCancel={() => setModalVisible(false)}>
            <Grid>
                <Section>
                    <Field
                        title="Branch Name"
                        description="The branch on which you want this pipeline to run."
                        value={newBranchName}
                        onChange={(e: FormEvent<HTMLInputElement>) => {setNewBranchName(e.currentTarget.value)}}
                    />
                </Section>
                <Section>
                    <Button
                        text={"Add Pipeline Definition"}
                        onClick={() => {
                            if(newBranchName) {
                                setPipelineDefinitionsFile(
                                    produce(pipelineDefinitionsFile, draft => {
                                        draft.pipelines.branches[newBranchName] = {steps: []}
                                    })
                                )
                                setNewBranchName(undefined)
                                setBranch({default: false, name: newBranchName})
                                setModalVisible(false)

                            }
                        }}
                    />
                </Section>
            </Grid>
        </Modal>
    )

    return (
        <Grid>
            {modalVisible ? addPipelineDefinitionModal : null}
            <Section>
                <AutoComplete
                    availableItems={branchAutoCompleteItems}
                    onChange={({target}) => {
                        if (target.value) {
                            if (target.value.id === 0) setBranch({default: true})
                            else setBranch({default: false, name: target.value.name})
                        }
                    }}
                    value={branch.default ? {id: 0, name: "Default"} : {id: branch.name, name: branch.name}}
                    disabled={disabled}
                />
                <Section>
                    <Button
                        text={"Add new pipeline definition"}
                        onClick={() => setModalVisible(true)}
                    />
                </Section>
            </Section>
        </Grid>
    )
}

export default BranchControls