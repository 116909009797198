import {CollapsiblePanel, Label} from "@matillion/component-library";
import {PipelineStep, StepState} from "../interfaces/Pipeline";
import StepDetails from "./StepDetails";

interface StepsListProps {
    steps: PipelineStep[]
}

const StepsList = ({steps}: StepsListProps) => {
    const collapsibleSteps = steps.map((step) => {
        const stepBoarder = step.state === StepState.FAILED ? "red" : (step.state === StepState.FINISHED ? "green" : "grey")
        return (
            <div style={{border: `1px solid ${stepBoarder}`}}>
                <CollapsiblePanel
                    title={step.name}
                    subtitle={"Click for more details"}
                    theme="white"
                >
                    <StepDetails step={step}/>
                </CollapsiblePanel>
            </div>
        )
    })

    return (
        <div>
            <Label title={"Steps"}>
                <div>{collapsibleSteps}</div>
            </Label>
        </div>
    )
}

export default StepsList