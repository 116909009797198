import {CollapsiblePanel, Typography} from "@matillion/component-library";

interface LogsProps {
    logs: string[]
}

const Logs = ({logs}: LogsProps) => (
    <div style={{border: "1px solid lightgray"}}>
    <CollapsiblePanel
        title="Click to view logs"
        subtitle=""
        theme="white"
    >
            {
                logs.map(
                    (log) => (
                        <Typography>
                            {`- ${log}`.split('\n').map((line) => <><br/>{line}</>)}
                        </Typography>
                    )
                )
            }
    </CollapsiblePanel>
    </div>
)

export default Logs