import {Button, Card} from "@matillion/component-library";
import JsonAsTable from "../JsonAsTable";
import Cross from "@matillion/component-library/dist/assets/icons/cross-black.svg";
import {PipelineStepDefinition} from "../../interfaces/PipelineDefinitionsFile";
import {useHostname} from "@matillion/octo-react-util";

interface PipelineStepCardProps {
    step: PipelineStepDefinition
    setStep: (step: PipelineStepDefinition | undefined) => void
}

const PipelineStepCard = ({step, setStep}: PipelineStepCardProps) => {
    const hostname = useHostname()

    return (
        <Card style={{backgroundColor: "white", border: "1px solid gray"}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <table>
                    <tr><td><b>Name</b></td><td>{step.name}</td></tr>
                    <tr><td><b>Service</b></td><td>{step.service}</td></tr>
                    <tr><td><b>Action</b></td><td>{step.action}</td></tr>
                    {step.actionParameters ?
                        <tr><td><b>Action Parameters</b></td><td><JsonAsTable json={step.actionParameters} /></td></tr>
                        : null
                    }
                    {step.actionOutputVariableMapping ?
                        <tr><td><b>Action Output Variable Mapping</b></td><td><JsonAsTable json={step.actionOutputVariableMapping} /></td></tr>
                        : null
                    }
                </table>
                <Button
                    icon={hostname+Cross}
                    size="md"
                    alt={"secondary"}
                    onClick={() => setStep(undefined)}
                />
            </div>
        </Card>
    )
}

export default PipelineStepCard