export interface Pipeline {
    steps: PipelineStep[]
}

export interface PipelineStep {
    name: string,
    service: string,
    action: string,
    actionParameters?: { [parameter: string]: string },
    actionOutputVariableMapping?: { [outputName: string]: string },
    state: StepState,
    logs: string[],
    executionTimeMillis: number,
}

export enum StepState {
    READY = "READY",
    RUNNING = "RUNNING",
    FINISHED = "FINISHED",
    FAILED = "FAILED",
}