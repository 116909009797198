import CodeMirror from '@uiw/react-codemirror';
import {StreamLanguage} from "@codemirror/stream-parser";
import {yaml as yamlStreamParser} from "@codemirror/legacy-modes/mode/yaml";

interface YamlEditorProps {
    yaml: string,
    setYaml: (yaml: string) => void

}

const YamlEditor = ({yaml, setYaml}: YamlEditorProps) => {
    let yamlLanguage = StreamLanguage.define(yamlStreamParser)
    return (
        <CodeMirror
            value={yaml}
            extensions={[yamlLanguage]}
            onChange={(change: string) => setYaml(change)}
            indentWithTab={true}
        />
    )
}

export default YamlEditor