import {Button, Field, Grid, Section} from "@matillion/component-library";
import {FormEvent, useState} from "react";
import ModuleDefinitionFile from "../../interfaces/ModuleDefinitionFile";

interface ModuleInputVariablesControlsProps {
    moduleDefinitionFile: ModuleDefinitionFile
    setModuleDefinitionFile: (moduleDefinitionFile: ModuleDefinitionFile) => void
}

const ModuleInputVariablesControls = ({moduleDefinitionFile, setModuleDefinitionFile}: ModuleInputVariablesControlsProps) => {
    const [newInputVariable, setNewInputVariable] = useState<string>()

    function addInputVariable(name: string) {
        setModuleDefinitionFile(
            {
                inputVariables: [...(moduleDefinitionFile.inputVariables ?? []), name],
                steps: moduleDefinitionFile.steps
            }
        )
    }

    function removeInputVariable(name: string) {
        setModuleDefinitionFile(
            {
                inputVariables: moduleDefinitionFile.inputVariables.filter((variable) => variable !== name),
                steps: moduleDefinitionFile.steps
            }
        )
    }

    return (
        <Grid>
            <Section>
                <Field
                    title="Input variable name"
                    value={newInputVariable}
                    onChange={(e: FormEvent<HTMLInputElement>) => {setNewInputVariable(e.currentTarget.value)}}
                />
            </Section>
            <Section>
                <Button
                    text={"Add"}
                    onClick={() => {
                        if(newInputVariable) {
                            addInputVariable(newInputVariable)
                            setNewInputVariable(undefined)
                        }
                    }}
                />
            </Section>
            <Section>
                {moduleDefinitionFile.inputVariables.map((variable) =>
                    <li onClick={() => removeInputVariable(variable)}>{variable}</li>)
                }
            </Section>
        </Grid>
    )
}

export default ModuleInputVariablesControls