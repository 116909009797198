import {useState} from "react";
import yaml from "js-yaml";
import {Tab, Tabs, Typography} from "@matillion/component-library";
import YamlEditor from "./YamlEditor";
import ModuleDefinitionFile from "../../interfaces/ModuleDefinitionFile";
import GraphicalModuleEditor from "./GraphicalModuleEditor";
import ModuleSelector from "./ModuleSelector";

const ModuleDefinitionFileEditor = () => {
    // source of truth
    const [moduleDefinitionFile, setModuleDefinitionFile] = useState<ModuleDefinitionFile>()
    // temporary state to allow yaml editing. This will be used to update pipelineDefinitionsFile when tab changes
    const [yamlModuleDefinitionFile, setYamlModuleDefinitionFile] = useState<string>(yaml.dump(moduleDefinitionFile))
    const [moduleName, setModuleName] = useState<string>()

    const tabs = moduleDefinitionFile && moduleName ? (
        <>
            <Tabs
                tabFormat={"stretch"}
                onChange={change => {
                    switch (change.value) {
                        case 0:
                            setModuleDefinitionFile(yaml.load(yamlModuleDefinitionFile) as ModuleDefinitionFile)
                            break
                        case 1:
                            setYamlModuleDefinitionFile(yaml.dump(moduleDefinitionFile))
                            break
                    }
                }}
            >
                <Tab title={"Graphical Editor"}>
                    <GraphicalModuleEditor
                        moduleName={moduleName}
                        moduleDefinitionFile={moduleDefinitionFile}
                        setModuleDefinitionFile={setModuleDefinitionFile}
                    />
                </Tab>
                <Tab title={"YAML Editor"}>
                    <YamlEditor
                        yaml={yamlModuleDefinitionFile}
                        setYaml={setYamlModuleDefinitionFile}
                    />
                </Tab>
            </Tabs>
        </>
    ) : null

    return (
        <div>
            <Typography>Select module definition file to edit</Typography>
            <ModuleSelector
                moduleName={moduleName}
                setModuleName={setModuleName}
                setModuleDefinitionFile={setModuleDefinitionFile}
                setYamlModuleDefinitionFile={setYamlModuleDefinitionFile}
            />
            {tabs}
        </div>
    )
}

export default ModuleDefinitionFileEditor