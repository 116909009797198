import {Button, Grid, Section, Typography} from "@matillion/component-library"
import {PipelineBranchId, PipelineDefinition, PipelineDefinitionsFile} from "../../interfaces/PipelineDefinitionsFile";
import {useState} from "react";
import {useApi} from "@matillion/octo-react-util";
import Api from "../../api/Api";
import AddStepControls from "./AddStepControls";
import BranchControls from "./BranchControls";

interface GraphicalPipelineEditorControlsProps {
    pipelineDefinitionsFile: PipelineDefinitionsFile
    setPipelineDefinitionsFile: (pipelineDefinitionsFile: PipelineDefinitionsFile) => void
    branch: PipelineBranchId
    setBranch: (branch: PipelineBranchId) => void
    pipelineDefinition: PipelineDefinition
    setPipelineDefinition: (pipelineDefinition: PipelineDefinition) => void
}

const GraphicalPipelineEditorControls = ({pipelineDefinitionsFile, setPipelineDefinitionsFile, branch, setBranch, pipelineDefinition, setPipelineDefinition}: GraphicalPipelineEditorControlsProps) => {
    const [saving, setSaving] = useState(false)
    const api = useApi<Api>()

    return (
        <Grid>
            <Section>
                <Typography weight="bold" format="bcs">Pipeline Branch</Typography>
                <BranchControls
                    pipelineDefinitionsFile={pipelineDefinitionsFile}
                    setPipelineDefinitionsFile={setPipelineDefinitionsFile}
                    branch={branch}
                    setBranch={setBranch}
                    disabled={saving}
                />
            </Section>
            <Section>
                <Typography weight="bold" format="bcs">Add Step</Typography>
                <AddStepControls
                    steps={pipelineDefinition.steps}
                    setSteps={(steps) => setPipelineDefinition({steps: steps})}
                    disabled={saving}
                />
            </Section>
            <Section>
                <Button
                    text={"Save"}
                    waiting={saving}
                    onClick={async () => {
                        setSaving(true)
                        await api.savePipelineDefinitionsFile(pipelineDefinitionsFile)
                        setSaving(false)
                    }}
                />
            </Section>
        </Grid>
    )
}

export default GraphicalPipelineEditorControls