import {AutoComplete, Button, Grid, Input, Section, Typography} from "@matillion/component-library";
import {useApi, useAppProps} from "@matillion/octo-react-util";
import {FormEvent, useEffect, useState} from "react";
import {ActionParametersDocumentation, ParameterDocumentation} from "../../interfaces/PipelineServicesDocumentaion";
import {PipelineStepDefinition} from "../../interfaces/PipelineDefinitionsFile";
import produce from "immer";
import ActionParameterInput from "./ActionParameterInput";
import Api from "../../api/Api";

interface AppStepControlsProps {
    steps: PipelineStepDefinition[]
    setSteps: (steps: PipelineStepDefinition[]) => void
    disabled: boolean
}

const AddStepControls = ({steps, setSteps, disabled}: AppStepControlsProps) => {
    const {pipelineServicesDocumentation: {services}} = useAppProps()
    const [selectedName, setSelectedName] = useState<string>()
    const [selectedService, setSelectedService] = useState<string>()
    const [selectedAction, setSelectedAction] = useState<string>()
    const [selectedActionParameters, setSelectedActionParameters] = useState<Map<string, string>>(new Map())
    const [selectedActionOutputVariableMapping, setSelectedActionOutputVariableMapping] = useState<Map<string, string>>(new Map())
    const api = useApi<Api>()
    const [actionParameters, setActionParameters] = useState<{ [actionParameters: string]: ParameterDocumentation }>()

    function addStep() {
        if (selectedName && selectedService && selectedAction
            && !steps.some((step) => step.name === selectedName)) {
            setSteps(
                produce(steps, draft => {
                    const actionParameters = selectedActionParameters.size ? Object.fromEntries(selectedActionParameters) : undefined
                    const actionOutputVariableMapping = selectedActionOutputVariableMapping.size ? Object.fromEntries(selectedActionOutputVariableMapping) : undefined
                    draft.push(
                        {
                            name: selectedName,
                            service: selectedService,
                            action: selectedAction,
                            actionParameters: actionParameters,
                            actionOutputVariableMapping: actionOutputVariableMapping,
                        }
                    )
                })
            )
            setSelectedActionParameters(new Map())
            setSelectedActionOutputVariableMapping(new Map())
            setSelectedAction(undefined)
            setSelectedService(undefined)
        }
    }

    useEffect(() => {
        async function getActionParameters() {
            if (selectedService && selectedAction) {
                const actionParametersDocumentation = services[selectedService].actions[selectedAction].actionParametersDocumentation
                let actionParameters = actionParametersDocumentation.actionParameters;
                let otherParametersLocation = actionParametersDocumentation.otherParametersLocation;
                while (otherParametersLocation !== null) {
                    let location = otherParametersLocation;
                    selectedActionParameters.forEach((value: string, key: string) => {
                        location = location.replace(new RegExp('{' + key + '}', 'gi'), value)
                    });
                    if (!location.includes("{")) {
                        const otherActionParametersDocumentation = await api.get<ActionParametersDocumentation>(location)
                        actionParameters = {
                            ...actionParameters,
                            ...otherActionParametersDocumentation.actionParameters
                        }
                        otherParametersLocation = otherActionParametersDocumentation.otherParametersLocation
                    } else otherParametersLocation = null
                }
                setActionParameters(actionParameters)
            } else {
                setActionParameters(undefined)
            }
        }

        getActionParameters()
    }, [api, selectedAction, selectedActionParameters, selectedService, services])

    function getActionParametersTable() {
        if(actionParameters) {
            const tableRows = Object.entries<ParameterDocumentation>(actionParameters).map(value => {
                const [parameterName, parameterDocumentation] = value
                return <tr>
                    <td>{parameterName}</td>
                    <td>
                        <ActionParameterInput
                            disabled={disabled}
                            parameterDocumentation={parameterDocumentation}
                            selectedParameter={selectedActionParameters.get(parameterName)}
                            setSelectedParameter={(parameter: any) => {
                                const newSelectedActionParameters = new Map(selectedActionParameters)
                                newSelectedActionParameters.set(parameterName, parameter)
                                setSelectedActionParameters(newSelectedActionParameters)
                            }}
                        />
                    </td>
                </tr>
            })

            if (tableRows.length) return (
                <table>
                    {tableRows}
                </table>
            )
        }
    }
    const actionParametersTable = getActionParametersTable()

    function getActionOutputTable() {
        if (selectedService && selectedAction) {
            const actionOutput = services[selectedService].actions[selectedAction].actionOutputDocumentation.actionOutput

            const tableRows = Object.entries<string>(actionOutput).map(value => {
                const [outputName, outputType] = value
                return (
                    <tr>
                        <td>{outputName}</td>
                        <td>
                            <Input
                                placeholder={outputType}
                                value={selectedActionOutputVariableMapping.get(outputName)}
                                onChange={(e: FormEvent<HTMLInputElement>) => {
                                    const newSelectedActionOutputVariableMapping = new Map(selectedActionOutputVariableMapping)
                                    newSelectedActionOutputVariableMapping.set(outputName, e.currentTarget.value)
                                    setSelectedActionOutputVariableMapping(newSelectedActionOutputVariableMapping)
                                }}
                                disabled={disabled}
                            />
                        </td>
                    </tr>
                )
            })

            if(tableRows.length) return (
                <table>
                    {tableRows}
                </table>
            )
        }
    }
    const actionOutputTable = getActionOutputTable()

    return (
        <Grid>
            <Section>
                <Input
                    placeholder="Name"
                    value={selectedName}
                    onChange={(e: FormEvent<HTMLInputElement>) => setSelectedName(e.currentTarget.value)}
                    disabled={disabled}
                />
            </Section>
            <Section>
                <AutoComplete
                    placeholder={"Service"}
                    onChange={({target: {value}}) => {
                        if (value && value.name !== selectedService) {
                            setSelectedActionParameters(new Map())
                            setSelectedActionOutputVariableMapping(new Map())
                            setSelectedAction(undefined)
                            setSelectedService(value.name)
                        }
                    }}
                    availableItems={Object.keys(services).map((service) => ({id: service, name: service}))}
                    value={selectedService ? {id: selectedService, name: selectedService} : null}
                    disabled={disabled}
                />
            </Section>
            <Section>
                <AutoComplete
                    placeholder={"Action"}
                    onChange={({target: {value}}) => {
                        if (value && value.name !== selectedAction && selectedService) {
                            setSelectedAction(value.name)
                            setSelectedActionParameters(new Map())
                            setSelectedActionOutputVariableMapping(new Map())
                        }
                    }}
                    availableItems={selectedService ? Object.keys(services[selectedService].actions).map((action) => ({
                        id: action,
                        name: action
                    })) : []}
                    value={selectedAction ? {id: selectedAction, name: selectedAction} : null}
                    disabled={disabled}
                />
            </Section>
            {actionParametersTable ? <Section>
                <Typography>Action Parameters</Typography>
                {actionParametersTable}
            </Section> : null}
            {actionOutputTable ? <Section>
                <Typography>Action Output Variable Mapping</Typography>
                {actionOutputTable}
            </Section> : null}
            <Section>
                <Button
                    text={"Add Step"}
                    onClick={() => addStep()}
                    disabled={disabled}
                />
            </Section>
        </Grid>
    )
}

export default AddStepControls