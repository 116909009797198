import React from 'react';
import './App.css';
import Api from "./api/Api";
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {AppBase} from "@matillion/octo-react-util";
import PipelineBrowser from "./components/PipelineBrowser";
import {appPropsLocations} from "./Constants";

function App() {
    return (
        <AppBase
            apiProducer={(auth0: Auth0ContextInterface, orgId: string) => new Api(auth0, orgId)}
            applicationName={"Pipeline Console"}
            appPropsLocations={appPropsLocations}
        >
            <PipelineBrowser />
        </AppBase>
    );
}

export default App;
