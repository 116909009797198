import {HashRouter, Link, Route, Routes} from "react-router-dom";
import PipelineStateList from "./PipelineStateList";
import PipelineStateView from "./PipelineStateView";
import React from "react";
import DefinitionsFileEditor from "./DefinitionsFileEditor/DefinitionsFileEditor";

const PipelineBrowser = ({baseRoute = ""}: {baseRoute?: string}) => {
    return (
        <HashRouter>
            <Routes>
                <Route path={baseRoute} element={<PipelineStateList/>} />
                <Route path={`${baseRoute}/id/:id`} element={<PipelineStateView baseRoute={baseRoute}/>} />
                <Route path={`${baseRoute}/*`} element={<Link to={`/${baseRoute}`}>404 back to list of pipelines.</Link>} />
                <Route path={`${baseRoute}/edit`} element={<DefinitionsFileEditor baseRoute={baseRoute}/>} />
            </Routes>
        </HashRouter>
    )
}

export default PipelineBrowser