import {FormEvent, useEffect, useState} from "react";
import {useApi} from "@matillion/octo-react-util";
import {AutoComplete, Button, Field, Grid, Modal, Section} from "@matillion/component-library";
import ModuleDefinitionFile from "../../interfaces/ModuleDefinitionFile";
import yaml from "js-yaml";
import Api from "../../api/Api";

interface ModuleSelectorProps {
    moduleName: string | undefined
    setModuleName: (moduleName: string) => void
    setModuleDefinitionFile: (moduleDefinitionFile: ModuleDefinitionFile) => void
    setYamlModuleDefinitionFile: (yamlModuleDefinitionFile: string) => void
}

const ModuleSelector = ({moduleName, setModuleName, setModuleDefinitionFile, setYamlModuleDefinitionFile}: ModuleSelectorProps) => {
    const [names, setNames] = useState<string[]>()
    const api = useApi<Api>()
    const [modalVisible, setModalVisible] = useState(false)
    const [newModuleName, setNewModuleName] = useState<string>()

    useEffect(() => {
        api.getAllModuleDefinitionFileNames().then((names) => setNames(names))
    }, [api])

    useEffect(() => {
        if(moduleName) api.getModuleDefinitionFile(moduleName)
            .then((file) => {
                if(file) {
                    setModuleDefinitionFile(file)
                    setYamlModuleDefinitionFile(yaml.dump(file))
                }
            })
    }, [api, moduleName, setModuleDefinitionFile, setYamlModuleDefinitionFile])

    const addModuleModal = <Modal onCancel={() => setModalVisible(false)}>
        <Grid>
            <Section>
                <Field
                    title="Module Name"
                    description=""
                    value={newModuleName}
                    onChange={(e: FormEvent<HTMLInputElement>) => {setNewModuleName(e.currentTarget.value)}}
                />
            </Section>
            <Section>
                <Button
                    text={"Add module"}
                    onClick={async () => {
                        if(newModuleName) {
                            const newModule: ModuleDefinitionFile = {inputVariables: [], steps: []}
                            await api.saveModuleDefinitionFile(newModuleName, newModule)
                            setNewModuleName(newModuleName)
                            setNames([...(names ?? []), newModuleName])
                            setModalVisible(false)
                        }
                    }}
                />
            </Section>
        </Grid>
    </Modal>

    return (
        <div>
            {modalVisible ? addModuleModal : null}
            <AutoComplete
                onChange={({target}) => {
                    if (target.value) setModuleName(target.value.name)
                }}
                value={moduleName ? {id: moduleName, name: moduleName} : null}
                availableItems={names?.map((name) => ({id: name, name: name})) ?? []}
            />
            <Button
                text={"Add module"}
                onClick={() => setModalVisible(true)}
            />
        </div>
    )
}

export default ModuleSelector