import {useEffect, useState} from "react";

const useUpdatingState = <T extends unknown>(
    updateState: () => Promise<T>,
    updatingIntervalMs: number = 750,
) => {
    const [state, setState] = useState<T>()
    const [timer, setTimer] = useState<NodeJS.Timer>()
    const [updating, setUpdating] = useState(true)
    const [error, setError] = useState<Error>()

    useEffect(() => {
        if(!updating && timer) clearInterval(timer)
    }, [timer, updating])

    useEffect(() => {
        async function fetchAndSetState() {
            try {
                setState(await updateState())
            } catch (e) {
                if(e instanceof Error) setError(e)
            }
        }
        fetchAndSetState()
        const interval = setInterval(async () => fetchAndSetState(), updatingIntervalMs);
        setTimer(interval)
        return () => clearInterval(interval)
    }, [updateState, updatingIntervalMs])

    return {state: state, setUpdating: setUpdating, error: error}
}

export default useUpdatingState