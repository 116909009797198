import {Button, Tab, Tabs} from "@matillion/component-library";
import {Link} from "react-router-dom";
import PipelineDefinitionsFileEditor from "./PipelineDefinitionsFileEditor";
import ModuleDefinitionFileEditor from "./ModuleDefinitionFileEditor";

const DefinitionsFileEditor = ({baseRoute}: {baseRoute: string}) => {
    return (
        <div>
            <Tabs
                tabFormat={"stretch"}
            >
                <Tab title={"Pipeline Editor"}>
                    <PipelineDefinitionsFileEditor />
                </Tab>
                <Tab title={"Module Editor"}>
                    <ModuleDefinitionFileEditor />
                </Tab>
            </Tabs>
            <Link to={`/${baseRoute}`}><Button alt={"text"}>Back to list</Button></Link>
        </div>
    )
}

export default DefinitionsFileEditor