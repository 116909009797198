import {PipelineStepDefinition} from "../../interfaces/PipelineDefinitionsFile";
import DraggableSteps from "./DraggableSteps";
import ModuleDefinitionFile from "../../interfaces/ModuleDefinitionFile";
import GraphicalModuleEditorControls from "./GraphicalModuleEditorControls";

interface GraphicalModuleEditorProps {
    moduleName: string
    moduleDefinitionFile: ModuleDefinitionFile
    setModuleDefinitionFile: (moduleDefinitionFile: ModuleDefinitionFile) => void
}

function GraphicalModuleEditor({moduleName, moduleDefinitionFile, setModuleDefinitionFile}: GraphicalModuleEditorProps) {
    return (
        <div style={{display: "flex", flexDirection: "row", width: "100%", gap: "5px"}}>
            <div style={{display: "flex", flexDirection: "column", width: "50%"}}>
                <GraphicalModuleEditorControls
                    moduleName={moduleName}
                    moduleDefinitionFile={moduleDefinitionFile}
                    setModuleDefinitionFile={setModuleDefinitionFile}
                />
            </div>
            <div style={{display: "flex", flexDirection: "column", width: "50%"}}>
                <DraggableSteps
                    steps={moduleDefinitionFile.steps}
                    setSteps={(steps: PipelineStepDefinition[]) => {
                        setModuleDefinitionFile({inputVariables: moduleDefinitionFile?.inputVariables, steps: steps})
                    }}
                />
            </div>
        </div>
    )
}

export default GraphicalModuleEditor