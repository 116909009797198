import {Button, Grid, Section, Typography} from "@matillion/component-library"
import {useState} from "react";
import {useApi} from "@matillion/octo-react-util";
import Api from "../../api/Api";
import ModuleDefinitionFile from "../../interfaces/ModuleDefinitionFile";
import AddStepControls from "./AddStepControls";
import ModuleInputVariablesControls from "./ModuleInputVariablesControls";

interface GraphicalModuleEditorControlsProps {
    moduleName: string
    moduleDefinitionFile: ModuleDefinitionFile
    setModuleDefinitionFile: (moduleDefinitionFile: ModuleDefinitionFile) => void
}

const GraphicalModuleEditorControls = ({moduleName, moduleDefinitionFile, setModuleDefinitionFile}: GraphicalModuleEditorControlsProps) => {
    const [saving, setSaving] = useState(false)

    const api = useApi<Api>()

    return (
        <Grid>
            <Section>
                <Typography weight="bold" format="bcs">Add Input Variable</Typography>
                <ModuleInputVariablesControls moduleDefinitionFile={moduleDefinitionFile} setModuleDefinitionFile={setModuleDefinitionFile}/>
            </Section>
            <Section>
                <Typography weight="bold" format="bcs">Add Step</Typography>
                <AddStepControls
                    steps={moduleDefinitionFile.steps}
                    setSteps={(steps) => setModuleDefinitionFile({...moduleDefinitionFile, steps: steps})}
                    disabled={saving}
                />
            </Section>
            <Section>
                <Button
                    text={"Save"}
                    waiting={saving}
                    onClick={async () => {
                        setSaving(true)
                        await api.saveModuleDefinitionFile(moduleName, moduleDefinitionFile)
                        setSaving(false)
                    }}
                />
            </Section>
        </Grid>
    )
}

export default GraphicalModuleEditorControls