import {Icon, LoadingSpinner, Typography} from "@matillion/component-library";
import {StepState} from "../interfaces/Pipeline";
import {State} from "../interfaces/PipelineState";

interface StateProps {
    state: StepState | State
}

const StateIcon = ({state}: StateProps) => {

    function getIcon() {
        switch (state) {
            case StepState.READY:
                return <Icon.Minus/>
            case State.INITIALISING:
            case State.RUNNING:
            case StepState.RUNNING:
                return <LoadingSpinner/>
            case State.FINISHED:
            case StepState.FINISHED:
                return <Icon.Success/>
            case State.FAILED_TO_RUN:
            case State.FAILED_TO_TEAR_DOWN:
            case State.FAILED_TO_INITIALISE:
            case StepState.FAILED:
                return <Icon.Error/>
        }
    }

    const icon = getIcon()

    return (
        <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
            {icon}
            <Typography>{state}</Typography>
        </div>
    )
}

export default StateIcon