import {useEffect, useState} from "react";
import {useApi} from "@matillion/octo-react-util";
import Api from "../../api/Api";
import {Button, LoadingSpinner, Tab, Tabs, Typography} from "@matillion/component-library";
import GraphicalPipelineEditor from "./GraphicalPipelineEditor";
import {PipelineDefinitionsFile} from "../../interfaces/PipelineDefinitionsFile";
import YamlEditor from "./YamlEditor";
import yaml from "js-yaml";

const PipelineDefinitionsFileEditor = () => {
    const api = useApi<Api>()
    // source of truth
    const [pipelineDefinitionsFile, setPipelineDefinitionsFile] = useState<PipelineDefinitionsFile>()
    // temporary state to allow yaml editing. This will be used to update pipelineDefinitionsFile when tab changes
    const [yamlPipelineDefinitionsFile, setYamlPipelineDefinitionsFile] = useState<string>(yaml.dump(pipelineDefinitionsFile))
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        api.getPipelineDefinitionsFile()
            .then((fetchedPipelineDefinitionsFile) => {
                setPipelineDefinitionsFile(fetchedPipelineDefinitionsFile)
                setLoading(false)
            })
    }, [api])

    if(loading) return <LoadingSpinner/>
    if(!pipelineDefinitionsFile) return (
        <div>
            <Typography>No pipeline definition file exists.</Typography>
            <Button
                text={"Create empty pipeline definitions file"}
                onClick={async () => {
                    setLoading(true)
                    const newPipelineDefinitionsFile = {pipelines: {default: {steps: []}, branches: {}}}
                    await api.savePipelineDefinitionsFile(newPipelineDefinitionsFile)
                    setPipelineDefinitionsFile(newPipelineDefinitionsFile)
                    setLoading(false)
                }}
            />
        </div>
    )
    else return (
        <div>
            <Tabs
                tabFormat={"stretch"}
                onChange={change => {
                    switch (change.value) {
                        case 0:
                            setPipelineDefinitionsFile(yaml.load(yamlPipelineDefinitionsFile) as PipelineDefinitionsFile)
                            break
                        case 1:
                            setYamlPipelineDefinitionsFile(yaml.dump(pipelineDefinitionsFile))
                            break
                    }
                }}
            >
                <Tab title={"Graphical Editor"}>
                    <GraphicalPipelineEditor pipelineDefinitionsFile={pipelineDefinitionsFile} setPipelineDefinitionsFile={setPipelineDefinitionsFile}/>
                </Tab>
                <Tab title={"YAML Editor"}>
                    <YamlEditor
                        yaml={yamlPipelineDefinitionsFile}
                        setYaml={setYamlPipelineDefinitionsFile}
                    />
                </Tab>
            </Tabs>
        </div>
    )
}

export default PipelineDefinitionsFileEditor