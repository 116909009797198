import {useState} from "react";
import {
    PipelineBranchId,
    PipelineDefinition,
    PipelineDefinitionsFile,
    PipelineStepDefinition
} from "../../interfaces/PipelineDefinitionsFile";
import GraphicalPipelineEditorControls from "./GraphicalPipelineEditorControls";
import DraggableSteps from "./DraggableSteps";
import produce from "immer";

interface GraphicalPipelineEditorProps {
    pipelineDefinitionsFile: PipelineDefinitionsFile
    setPipelineDefinitionsFile: (pipelineDefinitionsFile: PipelineDefinitionsFile) => void
}

function GraphicalPipelineEditor({pipelineDefinitionsFile, setPipelineDefinitionsFile}: GraphicalPipelineEditorProps) {
    const [branch, setBranch] = useState<PipelineBranchId>({default: true})

    function setPipelineDefinition(pipeline: PipelineDefinition | undefined) {
        setPipelineDefinitionsFile(
            produce(pipelineDefinitionsFile, draft => {
                if(pipeline) {
                    if(branch.default) draft.pipelines.default = pipeline
                    else draft.pipelines.branches[branch.name] = pipeline
                } else {
                    if(branch.default) throw Error("Cannot delete default pipeline.")
                    else delete draft.pipelines.branches[branch.name]
                }
            })
        );
    }

    const pipelineDefinition = branch.default ? pipelineDefinitionsFile.pipelines.default : pipelineDefinitionsFile.pipelines.branches[branch.name]

    return (
        <div style={{display: "flex", flexDirection: "row", width: "100%", gap: "5px"}}>
            <div style={{display: "flex", flexDirection: "column", width: "50%"}}>
                <GraphicalPipelineEditorControls
                    pipelineDefinitionsFile={pipelineDefinitionsFile}
                    setPipelineDefinitionsFile={setPipelineDefinitionsFile}
                    branch={branch}
                    setBranch={setBranch}
                    pipelineDefinition={pipelineDefinition}
                    setPipelineDefinition={setPipelineDefinition}
                />
            </div>
            <div style={{display: "flex", flexDirection: "column", width: "50%"}}>
                <DraggableSteps
                    steps={pipelineDefinition.steps}
                    setSteps={(steps: PipelineStepDefinition[]) => setPipelineDefinition({steps: steps})}
                />
            </div>
        </div>
    )
}

export default GraphicalPipelineEditor